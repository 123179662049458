import { useRecoilState } from "recoil";
import { selectedMenuItem } from "../common/Atoms";
import { MenuItem } from "../common/Constants";
import "../styles/Menu.less";

const menu_items = [MenuItem.home, MenuItem.about, MenuItem.contact];

export function Menu() {
  const [menuItem, setMenuItem] = useRecoilState(selectedMenuItem);

  return (
    <div className="menu">
      {menu_items.map((value, index) => (
        <div
          key={index}
          className={value === menuItem ? "selected-menu-item" : undefined}
          onClick={() => {
            setMenuItem(value);
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );
}
