import { useEffect, useState } from "react";

export function LiveDate() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return <div className="placeholder">{date.toLocaleTimeString()}</div>;
}
