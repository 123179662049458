import { RecoilRoot } from "recoil";
import { Body } from "./components/Body";
import { FULL_NAME } from "./common/Constants";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Menu } from "./components/Menu";
import "./App.less";

function App() {
  return (
    <RecoilRoot>
      <div className="app">
        <Header text={FULL_NAME} />
        <Menu />
        <Body />
        <Footer />
      </div>
    </RecoilRoot>
  );
}

export default App;
