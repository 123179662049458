import { useState } from "react";
import { Link } from "../common/Link";
import { isValidEmail, sendMail } from "../common/Utils";
import RingLoader from "react-spinners/RingLoader";
import "../styles/Contact.less";
// Import the functions you need from the SDKs you need
// import { getAnalytics } from "firebase/analytics";

enum Status {
  FORM,
  SENT,
  FAILED,
}

export function Contact() {
  const [pageStatus, setPageStatus] = useState<Status>(Status.FORM);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageError, setMessageError] = useState<boolean>(false);

  if (pageStatus === Status.SENT) {
    return (
      <>
        <div className="sent">Your message was sent successfully!</div>
        <Link
          className="link"
          text="Send another message!"
          onClick={() => {
            setPageStatus(Status.FORM);
            setName("");
            setEmail("");
            setMessage("");
            setMessageError(false);
          }}
        />
      </>
    );
  }
  if (pageStatus === Status.FAILED) {
    return (
      <>
        <div className="failed">
          Your message failed! Please try again later.
        </div>
        <Link
          className="link"
          text="Try again!"
          onClick={() => setPageStatus(Status.FORM)}
        />
      </>
    );
  }
  return loading ? (
    <div className="spinner">
      <RingLoader color="red" size={100} />
    </div>
  ) : (
    // TODO add a global boolean switch in the DB to enable/disable contact form
    <div className="contact">
      <Row
        label="Name: "
        invalid={nameError}
        errorMessage="This field cannot be empty!"
      >
        <input
          type="text"
          placeholder="Type your name..."
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(!e.target.value);
          }}
        />
      </Row>
      <Row
        label="Email: "
        invalid={emailError}
        errorMessage="Please enter a valid email address!"
      >
        <input
          type="email"
          placeholder="Type your email..."
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(!isValidEmail(e.target.value));
          }}
        />
      </Row>
      <Row
        label="Message: "
        invalid={messageError}
        errorMessage="This field cannot be empty!"
      >
        <textarea
          placeholder="Type your message..."
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setMessageError(!e.target.value);
          }}
        />
      </Row>
      <div>
        <button
          onClick={() => {
            if (!name) {
              setNameError(true);
            }
            if (!isValidEmail(email)) {
              setEmailError(true);
            }
            if (!message) {
              setMessageError(true);
            }
            if (name && isValidEmail(email) && message) {
              setLoading(true);
              sendMail({ name, email, message })
                .then((result: any) => {
                  // console.log(result.data);
                  setPageStatus(Status.SENT);
                })
                .catch((error) => {
                  console.error(error);
                  setPageStatus(Status.FAILED);
                })
                .finally(() => {
                  setLoading(false);
                  // console.log('Name: ', name);
                  // console.log('Email: ', email);
                  // console.log('Message: ', message);
                });
            }
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
}

type RowProps = {
  label: string;
  children: JSX.Element;
  invalid: boolean;
  errorMessage: string;
};

function Row(props: RowProps) {
  return (
    <div className="row">
      <div className="label-box">
        <div className="row-label">{props.label}</div>
        {props.invalid ? (
          <div className="row-error">{props.errorMessage}</div>
        ) : null}
      </div>
      {props.children}
    </div>
  );
}
