type LinkProps = {
  text: string;
  className: string;
  onClick: () => void;
};

export function Link(props: LinkProps) {
  return (
    <div className={props.className} onClick={props.onClick}>
      {props.text}
    </div>
  );
}
