import { useRecoilValue } from "recoil";
import { selectedMenuItem } from "../common/Atoms";
import { MenuItem } from "../common/Constants";
import { Contact } from "./Contact";
import { LiveDate } from "../common/LiveDate";
import "../styles/styles.less";

const wipComponent = (
  <>
    <LiveDate />
    <div className="placeholder">Work in progress...</div>
  </>
);

const menuItemMap = {
  [MenuItem.home]: wipComponent,
  [MenuItem.about]: wipComponent,
  [MenuItem.contact]: <Contact />,
};

export function Body() {
  const selectedMenu = useRecoilValue(selectedMenuItem);

  return <div className="body">{menuItemMap[selectedMenu]}</div>;
}
