import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { firebaseConfig, MailData } from "../common/Constants";

export function isValidEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}

export async function sendMail(
  props: MailData
): Promise<HttpsCallableResult<unknown>> {
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      "6LdHURchAAAAAB5Gj_Fi2ONMXaiQMfvU4Vga1wzk"
    ),
    isTokenAutoRefreshEnabled: true,
  });

  const functions = getFunctions(appCheck.app);
  const sendMessage = httpsCallable(functions, "sendMail");
  return sendMessage(props);
}
